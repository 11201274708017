<template>
  <div class="container mt-5">
    <div class="row">
      <div
        class="col-md-3"
        v-for="(list, index) in listas" :key="list.id">
        <h3>=> {{ index }}</h3>
        
        <draggable
          class="card"
          :id="index"
          :list="list"
          :move = "checkMove"
          group="people"
          @change="log"
          @add="ADD"
          @remove="remove"
          itemKey="name"
        >
            <template #item="{ element, index }">
                <div 
                class="card"
                v-if="element.name"
                >
                    {{ element.name }} {{ index }}
                  <p>empresa</p>
                </div>
            </template>
            <template #header>
              <input class="input" @keyup.enter="add($event, index)" :placeholder="'novo ' + index">
            </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import axios from "axios";

export default {
  name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable
  },
  data() {
    return {
      listas: {},
      endpoints : ["lead", "kalificado"],
      newLeadName: ""
    };
  },
  methods: {
    add: function(el, index) {
      const newLead = el.target.value
      this.listas[index].push({ name: newLead});
      el.target.value=''
      //this.addLead(newLead, index)
    },

    ADD: function(evt) {
      console.log("ADD evt", evt)
      const lead = evt.item.__draggable_context.element.name
      const index = evt.to.id
      console.log("adicionar =>",index,lead)
      this.addLead(lead, index)
    },

    remove: function(evt) {
      console.log("REMOVE evt", evt)
      const id = evt.item.__draggable_context.element.id
      const index = evt.from.id
      console.log("remover =>",index,id)
      this.removeLead(id, index)
    },

    async addLead(newLead, index) {
      if (newLead) {
        console.log("adicionando lead na api POST")
        try {
          await axios.post(`${process.env.VUE_APP_JSON_SERVER}/${index}`, {
            name: newLead,
          });
          //this.fetchData();

        } catch (error) {
          console.error("Error ao adicionar lead:", error);
        }
      }
    },

    async removeLead(id, index) {
      if (id) {
        console.log("removendo lead na api POST")
        try {
          await axios.delete(`${process.env.VUE_APP_JSON_SERVER}/${index}/${id}`);
         // this.fetchData();

        } catch (error) {
          console.error("Error ao remover lead:", error);
        }
      }
    },

    clone: function(el) {
      console.log('clone' + el)
      return {
        name: el.name + " cloned"
      };
    },
    log: function(evt) {
      window.console.log("log",evt);
    },
    checkMove: function(evt){
      console.log("MOVE evt =>",evt)
      console.log(evt.to.id)
      // console.log("element original =>", evt.draggedContext)
      // console.log("element original id =>", evt.draggedContext.element.id)


      //console.log("list moved =>", evt.relatedContext.list)

      // console.log("OriginalEvent => ", originalEvent)

      return (evt.draggedContext.element.name!='Joao');
    },

    async fetchData() {
      try {
        for (const endpoint of this.endpoints) {
          const response = await axios.get(`${process.env.VUE_APP_JSON_SERVER}/${endpoint}`);
          this.listas[endpoint] = response.data;
        }
      } catch (error) {
        console.error("Erro ao buscar dados:", error);
      }
    },
  },
  async created() {
    await this.fetchData(this.endpoints)
    console.log("JSON-SERVER =>",process.env.VUE_APP_JSON_SERVER)
  },

}
</script>

  <style>
      .card {
        background-color: #3a195f;
        border: 2px solid #007bff;
        border-radius: 5px;
        margin-bottom: 10px;
        margin-right: 10px;
        padding: 10px;
    }

      .input {
          background-color: #00000000;
          border: 2px solid #007bff;
          border-radius: 5px;
          margin-bottom: 10px;
          margin-right: 10px;
          padding: 10px;
      }
  </style>